import Immutable from "seamless-immutable";
import * as agentAction from "../actions/agent";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as agentSelectors from "../selectors/agent";
import * as profileSelectors from "../selectors/profile";
import _ from "lodash";
import * as authActions from "../actions/auth";
import * as profileActions from "../actions/profile";
import Toast from "../utils/Toast";
import { updateProfileDetail } from "../actions/profile";
import { getAgentCommonCityRequest } from "../actions/agent";

export default api => {
  function* watcherPostAgentAccountRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentAccount, postData);

      const { accessToken, profile, messages } = response.data;

      if (!_.isEmpty(messages[0].text)) {
        Toast.success(messages[0].text);
      }
      yield put(profileActions.updateProfileDetail(profile));
      yield put(authActions.updateAccessToken(accessToken));
      const agent = profileSelectors.getProfileAgent(profile);
      if (!_.isEmpty(agent)) {
        const agentToken = profileSelectors.getAgentAccessToken(_.first(agent));
        yield put(authActions.updateAgentAccessToken(agentToken));
        yield put(agentAction.getAgentCommonDataRequest());
      }
      yield put(agentAction.postAgentAccountSuccess(response.data));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentAccountFailure,
        effects
      );
    }
  }

  function* watcherPostAgentAccountVerifyRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentAccountVerify, postData);

      const { accessToken, profile } = response.data;

      const agent = profileSelectors.getProfileAgent(profile);
      if (!_.isEmpty(agent)) {
        const token = profileSelectors.getAgentAccessToken(_.first(agent));
        yield put(authActions.updateAgentAccessToken(token));
        yield put(agentAction.getAgentCommonDataRequestInLocal());
      }
      yield put(authActions.updateAccessToken(accessToken));

      yield put(profileActions.updateProfileDetail(profile));

      yield put(agentAction.postAgentAccountVerifySuccess(response.data));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentAccountVerifyFailure,
        effects
      );
    }
  }

  function* watcherPostAgentSearchAgencyRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentSearchAgency, postData);

      const { items } = response.data;

      yield put(agentAction.postAgentSearchAgencySuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentSearchAgencyFailure,
        effects
      );
    }
  }

  function* watcherPostAgentSearchProjectRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentSearchProject, postData);

      const { items } = response.data;

      yield put(agentAction.postAgentSearchProjectSuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentSearchProjectFailure,
        effects
      );
    }
  }

  function* watcherPostAgentListingRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentListing, postData);

      const { item } = response.data;

      const id = agentSelectors.getAgentListingID(item);

      yield put(agentAction.postAgentListingSuccess(id, item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentListingFailure,
        effects
      );
    }
  }
  function* watcherPostAgentListingv2Request({ page, postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentListingv2, page, postData);

      const { items, _meta } = response.data;

      yield put(agentAction.postAgentListingv2Success(page, items, _meta));

    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentListingv2Failure,
        effects
      );
    }
  }

  function* watcherPutAgentListingDataRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putAgentListingData, id, putData);

      const { item } = response.data;

      yield put(agentAction.putAgentListingDataSuccess(id, item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.putAgentListingDataFailure,
        effects
      );
    }
  }

  function* watcherGetAgentListingRequest(
    { page, status, propertyStatus, keyword}, effects) {
    const { call, put } = effects;

    try {
      const response = yield call(
        api.getAgentListing,
        page,
        status,
        propertyStatus,
        keyword
      );

      const { items, _meta, name} = response.data;
      yield put(agentAction.getAgentListingSuccess(page, items, _meta, name));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.getAgentListingFailure,
        effects
      );
    }
  }

  function* watcherGetAgentListingDetailRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAgentListingDetail, id);

      yield put(agentAction.getAgentListingDataSuccess(id, response.data));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.getAgentListingDataFailure,
        effects
      );
    }
  }
  function* watcherGetAgentCommonDataRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAgentCommonData);

      yield put(agentAction.getAgentCommonDataSuccess(response.data));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.getAgentCommonDataFailure,
        effects
      );
    }
  }

  function* watcherGetAgentCommonStateRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAgentCommonState);
      const { items } = response.data;
      yield put(agentAction.getAgentCommonStateSuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.getAgentCommonStateFailure,
        effects
      );
    }
  }

  function* watcherGetAgentCommonCityRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAgentCommonCity);
      const { items } = response.data;
      yield put(agentAction.getAgentCommonCitySuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.getAgentCommonCityFailure,
        effects
      );
    }
  }

  function* watcherPostAgentListingDataImageRequest(
    { id, postData, randomID },
    effects
  ) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentListingDataImage, id, postData);

      const { item } = response.data;

      yield put(
        agentAction.postAgentListingDataImageSuccess(id, item, randomID)
      );
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentListingDataImageFailure,
        effects,
        false,
        false,
        randomID
      );
    }
  }

  function* watcherPostAgentListingDataImageDefaultRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentListingDataImageDefault, id);

      const { item } = response.data;
      yield put(agentAction.postAgentListingDataImageDefaultSuccess(id, id));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentListingDataImageDefaultFailure,
        effects,
        false,
        false,
        id
      );
    }
  }

  function* watcherDeleteAgentListingImageRequest(
    { id, postData, randomID },
    effects
  ) {
    const { call, put } = effects;
    try {
      const response = yield call(api.deleteAgentListingImageData, id);
      const { item } = response.data;

      yield put(agentAction.deleteAgentListingImageDataSuccess(id));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.deleteAgentListingImageDataFailure,
        effects,
        false,
        false,
        id
      );
    }
  }

  function* watcherPostAgentListingDataPublishRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentListingDataPublish, id);
      const { item } = response.data;

      yield put(agentAction.postAgentListingDataPublishSuccess(item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentListingDataPublishFailure,
        effects
      );
    }
  }

  function* watcherPostAgentSearchStateRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentSearchState, postData);

      const { items } = response.data;

      yield put(agentAction.postAgentSearchStateSuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentSearchStateFailure,
        effects
      );
    }
  }

  function* watcherPostAgentSearchCityRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentSearchCity, postData);

      const { items } = response.data;

      yield put(agentAction.postAgentSearchCitySuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentSearchCityFailure,
        effects
      );
    }
  }

  function* watcherPostAgentProjectCreateRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentProjectCreate, postData);

      const { item } = response.data;

      yield put(agentAction.postAgentProjectCreateSuccess(item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentProjectCreateFailure,
        effects
      );
    }
  }

  function* watcherGetAgentLeadListRequest(
    { page, listingStatus, createdAt },
    effects
  ) {
    const { call, put } = effects;
    try {
      const response = yield call(
        api.getAgentLead,
        page,
        listingStatus,
        createdAt
      );

      const { items, _meta } = response.data;

      yield put(agentAction.getAgentLeadListSuccess(items, _meta, page));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.getAgentLeadListFailure,
        effects
      );
    }
  }

  function* watcherPutAgentLeadDetailRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putAgentLeadDetail, id, putData);

      const { accessToken, items, messages } = response.data;

      if (!_.isEmpty(messages[0].text)) {
        Toast.success(messages[0].text);
      }

      yield put(agentAction.putAgentLeadDetailSuccess(id, items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.putAgentLeadDetailFailure,
        effects
      );
    }
  }

  function* watcherPutAgentListingDetailImageRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putAgentListingDetailImage, id, putData);
      const { item } = response.data;

      yield put(agentAction.putAgentListingDetailImageSuccess(item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.putAgentListingDetailImageFailure,
        effects
      );
    }
  }

  function* watcherPostAgentSubscriptionPackageRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAgentSubscriptionPackage, id);

      const { cart } = response.data;

      yield put(agentAction.postAgentSubscriptionPackageSuccess(cart));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.postAgentSubscriptionPackageFailure,
        effects
      );
    }
  }

  const INITIAL_STATE = Immutable({
    agent: null
  });

  return {
    namespace: "agent",
    state: INITIAL_STATE,
    reducers: {
      POST_AGENT_ACCOUNT_REQUEST(state, action) {
        return state
          .setIn(["agentAccount", "loading"], true)
          .setIn(["agentAccount", "status"], false);
      },
      POST_AGENT_ACCOUNT_SUCCESS(state, action) {
        return state
          .setIn(["agentAccount", "loading"], false)
          .setIn(["agentAccount", "status"], true);
      },
      POST_AGENT_ACCOUNT_FAILURE(state, action) {
        return state
          .setIn(["agentAccount", "loading"], false)
          .setIn(["agentAccount", "status"], false);
      },
      POST_AGENT_ACCOUNT_VERIFY_REQUEST(state, action) {
        return state
          .setIn(["agentAccountVerify", "loading"], true)
          .setIn(["agentAccountVerify", "status"], false);
      },
      POST_AGENT_ACCOUNT_VERIFY_SUCCESS(state, action) {
        return state
          .setIn(["agentAccountVerify", "loading"], false)
          .setIn(["agentAccountVerify", "status"], true);
      },
      POST_AGENT_ACCOUNT_VERIFY_FAILURE(state, action) {
        return state
          .setIn(["agentAccountVerify", "loading"], false)
          .setIn(["agentAccountVerify", "status"], false);
      },
      POST_AGENT_SEARCH_AGENCY_REQUEST(state, action) {
        return state.setIn(["agentSearchAgency", "loading"], true);
      },
      POST_AGENT_SEARCH_AGENCY_SUCCESS(state, action) {
        return state
          .setIn(["agentSearchAgency", "loading"], false)
          .setIn(["agentSearchAgency", "items"], action.data);
      },
      POST_AGENT_SEARCH_AGENCY_FAILURE(state, action) {
        return state.setIn(["agentSearchAgency", "loading"], false);
      },
      POST_AGENT_SEARCH_PROJECT_REQUEST(state) {
        return state.setIn(["agentSearchProject", "loading"], true);
      },
      POST_AGENT_SEARCH_PROJECT_SUCCESS(state, action) {
        return state
          .setIn(["agentSearchProject", "loading"], false)
          .setIn(["agentSearchProject", "items"], action.data);
      },
      POST_AGENT_SEARCH_PROJECT_FAILURE(state) {
        return state.setIn(["agentSearchProject", "loading"], false);
      },
      POST_AGENT_LISTING_REQUEST(state) {
        return state
          .setIn(["postAgentListing", "loading"], true)
          .setIn(["postAgentListing", "status"], false);
      },
      POST_AGENT_LISTING_SUCCESS(state, action) {
        return state
          .setIn(["postAgentListing", "loading"], false)
          .setIn(["postAgentListing", "status"], true)
          .setIn(["postAgentListing", "item"], action.data)
          .setIn(["agentListingData", "items", action.id], action.data);
      },
      POST_AGENT_LISTING_FAILURE(state) {
        return state
          .setIn(["postAgentListing", "loading"], false)
          .setIn(["postAgentListing", "status"], false);
      },
      POST_AGENT_LISTING_v2_REQUEST(state) {
        return state
          .setIn(["postAgentListing", "loading"], true)
      },
      POST_AGENT_LISTING_v2_SUCCESS(state, action) {
        return state
          .setIn(["postAgentListing", "loading"], false)
          .setIn(["postAgentListing", "items"], action.data)
          .setIn(["postAgentListing", "pagination"], action.pagination)
      },
      POST_AGENT_LISTING_v2_FAILURE(state) {
        return state
          .setIn(["postAgentListing", "loading"], false)
          .setIn(["postAgentListing", "status"], false);
      },
      PUT_AGENT_LISTING_DATA_REQUEST(state) {
        return state
          .setIn(["putAgentListing", "loading"], true)
          .setIn(["putAgentListing", "status"], false);
      },
      PUT_AGENT_LISTING_DATA_SUCCESS(state, action) {
        return state
          .setIn(["putAgentListing", "loading"], false)
          .setIn(["agentListingData", "items", action.id], action.data)
          .setIn(["putAgentListing", "status"], true);
      },
      PUT_AGENT_LISTING_DATA_FAILURE(state) {
        return state
          .setIn(["putAgentListing", "loading"], false)
          .setIn(["putAgentListing", "status"], false);
      },
      SET_CURRENT_AGENT_PROJECT_ID(state, action) {
        return state.setIn(["currentProjectID"], action.id);
      },
      GET_AGENT_LISTING_REQUEST(state) {
        return state.setIn(["agentListing", "loading"], true);
      },
      GET_AGENT_LISTING_SUCCESS(state, action) {
        return state
          .setIn(["agentListing", "loading"], false)
          .setIn(["agentListing", "items", action.page], action.data)
          .setIn(["agentListing", "pagination"], action.pagination);
      },
      GET_AGENT_LISTING_FAILURE(state) {
        return state.setIn(["agentListing", "loading"], false);
      },

      SET_FILTER_DATA(state, action) {
        return state.setIn(["filterData"], action.filterData);
      },

      GET_AGENT_LISTING_DETAIL_REQUEST(state) {
        return state.setIn(["agentListingDetail", "loading"], true);
      },
      GET_AGENT_LISTING_DETAIL_SUCCESS(state, action) {
        return state
          .setIn(["agentListingDetail", "loading"], false)
          .setIn(["agentListingData", "items", action.id], action.data);
      },
      GET_AGENT_LISTING_DETAIL_FAILURE(state) {
        return state.setIn(["agentListingDetail", "loading"], false);
      },
      GET_AGENT_COMMON_DATA_SUCCESS(state, action) {
        return state.setIn(["agentCommonData"], action.data);
      },
      POST_AGENT_LISTING_DATA_IMAGE_REQUEST(state, action) {
        return state
          .setIn(["agentListingImage", "loading", action.randomID], true)
          .setIn(["agentListingImage", "status", action.randomID], false);
      },
      POST_AGENT_LISTING_DATA_IMAGE_SUCCESS(state, action) {
        return state
          .setIn(["agentListingImage", "loading", action.randomID], false)
          .setIn(["agentListingImage", "image", action.randomID], action.data)
          .setIn(["agentListingImage", "status", action.randomID], true);
      },
      POST_AGENT_LISTING_DATA_IMAGE_FAILURE(state, action) {
        return state
          .setIn(["agentListingImage", "loading", action.randomID], false)
          .setIn(["agentListingImage", "status", action.randomID], false);
      },
      POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_REQUEST(state, action) {
        return state
          .setIn(["agentListingImageDefault", "loading", action.id], true)
          .setIn(["agentListingImageDefault", "image"], null)
          .setIn(["agentListingImageDefault", "status"], false);
      },
      POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_SUCCESS(state, action) {
        return state
          .setIn(["agentListingImageDefault", "loading", action.id], false)
          .setIn(["agentListingImageDefault", "image"], action.id)
          .setIn(["agentListingImageDefault", "status"], true);
      },
      POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_FAILURE(state, action) {
        return state
          .setIn(["agentListingImageDefault", "loading", action.id], false)
          .setIn(["agentListingImageDefault", "image"], null)
          .setIn(["agentListingImageDefault", "status"], false);
      },
      DELETE_AGENT_LISTING_IMAGE_DATA_REQUEST(state, action) {
        return state.setIn(["deleteListingImage", "loading", action.id], true);
      },
      DELETE_AGENT_LISTING_IMAGE_DATA_SUCCESS(state, action) {
        return state
          .setIn(["deleteListingImage", "loading", action.id], false)
          .setIn(["deleteListingImage", "image", action.id], action.id);
      },
      DELETE_AGENT_LISTING_IMAGE_DATA_FAILURE(state, action) {
        return state.setIn(["deleteListingImage", "loading", action.id], false);
      },
      POST_AGENT_LISTING_DATA_PUBLISH_REQUEST(state, action) {
        return state
          .setIn(["postDetailPublish", "loading"], true)
          .setIn(["postDetailPublish", "status"], false);
      },
      POST_AGENT_LISTING_DATA_PUBLISH_SUCCESS(state, action) {
        return state
          .setIn(["postDetailPublish", "loading"], false)
          .setIn(["postDetailPublish", "status"], true);
      },
      POST_AGENT_LISTING_DATA_PUBLISH_FAILURE(state, action) {
        return state
          .setIn(["postDetailPublish", "loading"], false)
          .setIn(["postDetailPublish", "status"], false);
      },
      POST_AGENT_SEARCH_STATE_REQUEST(state) {
        return state.setIn(["postAgentSearchState", "loading"], true);
      },
      POST_AGENT_SEARCH_STATE_SUCCESS(state, action) {
        return state
          .setIn(["postAgentSearchState", "loading"], false)
          .setIn(["postAgentSearchState", "items"], action.data);
      },
      POST_AGENT_SEARCH_STATE_FAILURE(state) {
        return state.setIn(["postAgentSearchState", "loading"], false);
      },
      POST_AGENT_SEARCH_CITY_REQUEST(state) {
        return state.setIn(["postAgentSearchCity", "loading"], true);
      },
      POST_AGENT_SEARCH_CITY_SUCCESS(state, action) {
        return state
          .setIn(["postAgentSearchCity", "loading"], false)
          .setIn(["postAgentSearchCity", "items"], action.data);
      },
      POST_AGENT_SEARCH_CITY_FAILURE(state) {
        return state.setIn(["postAgentSearchCity", "loading"], false);
      },
      POST_AGENT_PROJECT_CREATE_REQUEST(state) {
        return state
          .setIn(["postAgentProjectCreate", "loading"], true)
          .setIn(["postAgentProjectCreate", "status"], false);
      },
      POST_AGENT_PROJECT_CREATE_SUCCESS(state, action) {
        return state
          .setIn(["postAgentProjectCreate", "loading"], false)
          .setIn(["postAgentProjectCreate", "status"], true)
          .setIn(["postAgentProjectCreate", "items"], action.data);
      },
      POST_AGENT_PROJECT_CREATE_FAILURE(state) {
        return state
          .setIn(["postAgentProjectCreate", "loading"], false)
          .setIn(["postAgentProjectCreate", "status"], false);
      },
      GET_AGENT_LEAD_LIST_REQUEST(state) {
        return state.setIn(["agentLead", "loading"], true);
      },
      GET_AGENT_LEAD_LIST_SUCCESS(state, action) {
        return state
          .setIn(["agentLead", "loading"], false)
          .setIn(["agentLead", "items", action.page], action.data)
          .setIn(["agentLead", "pagination"], action.pagination);
      },
      GET_AGENT_LEAD_LIST_FAILURE(state) {
        return state.setIn(["agentLead", "loading"], false);
      },
      PUT_AGENT_LEAD_DETAIL_REQUEST(state) {
        return state
          .setIn(["putAgentLeadDetail", "loading"], true)
          .setIn(["putAgentLeadDetail", "status"], false);
      },
      PUT_AGENT_LEAD_DETAIL_SUCCESS(state, action) {
        return state
          .setIn(["putAgentLeadDetail", "loading"], false)
          .setIn(["agentLeadDetail", action.id], action.data)
          .setIn(["putAgentLeadDetail", "status"], true);
      },
      PUT_AGENT_LEAD_DETAIL_FAILURE(state) {
        return state
          .setIn(["putAgentLeadDetail", "loading"], false)
          .setIn(["putAgentLeadDetail", "status"], false);
      },
      GET_AGENT_COMMON_CITY_SUCCESS(state, action) {
        return state.setIn(["agentCommonCity"], action.data);
      },
      GET_AGENT_COMMON_STATE_SUCCESS(state, action) {
        return state.setIn(["agentCommonState"], action.data);
      },
      GET_AGENT_COMMON_STAaE_SUCCESS(state, action) {
        return state.setIn(
          ["userPropertyListing", action.filterStatus, action.page],
          action.data
        );
      },
      // PUT_AGENT_LISTING_DETAIL_IMAGE_REQUEST(state,action){
      //   return
      // }
      //Subscription Package
      POST_AGENT_SUBSCRIPTION_PACKAGE_REQUEST(state) {
        return state
          .setIn(["agentSubscriptionPackage", "loading"], true)
          .setIn(["agentSubscriptionPackage", "status"], false);
      },
      POST_AGENT_SUBSCRIPTION_PACKAGE_SUCCESS(state, action) {
        return state
          .setIn(["agentSubscriptionPackage", "loading"], false)
          .setIn(["agentSubscriptionPackage", "status"], true)
          .setIn(["agentSubscriptionPackage","items"], action.data);
      },
      POST_AGENT_SUBSCRIPTION_PACKAGE_FAILURE(state) {
        return state
          .setIn(["agentSubscriptionPackage", "loading"], false)
          .setIn(["agentSubscriptionPackage", "status"], false);
      }
    },
    effects: {
      POST_AGENT_ACCOUNT_REQUEST: [watcherPostAgentAccountRequest],
      POST_AGENT_ACCOUNT_VERIFY_REQUEST: [watcherPostAgentAccountVerifyRequest],
      POST_AGENT_SEARCH_AGENCY_REQUEST: [watcherPostAgentSearchAgencyRequest],
      POST_AGENT_SEARCH_PROJECT_REQUEST: [watcherPostAgentSearchProjectRequest],
      POST_AGENT_LISTING_REQUEST: [watcherPostAgentListingRequest],
      POST_AGENT_LISTING_v2_REQUEST: [watcherPostAgentListingv2Request],
      PUT_AGENT_LISTING_DATA_REQUEST: [watcherPutAgentListingDataRequest],
      GET_AGENT_LISTING_REQUEST: [watcherGetAgentListingRequest],
      GET_AGENT_LISTING_DETAIL_REQUEST: [watcherGetAgentListingDetailRequest],
      GET_AGENT_COMMON_DATA_REQUEST: [watcherGetAgentCommonDataRequest],
      POST_AGENT_LISTING_DATA_IMAGE_REQUEST: [
        watcherPostAgentListingDataImageRequest
      ],
      POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_REQUEST: [
        watcherPostAgentListingDataImageDefaultRequest
      ],
      DELETE_AGENT_LISTING_IMAGE_DATA_REQUEST: [
        watcherDeleteAgentListingImageRequest
      ],
      POST_AGENT_LISTING_DATA_PUBLISH_REQUEST: [
        watcherPostAgentListingDataPublishRequest
      ],
      POST_AGENT_SEARCH_STATE_REQUEST: [watcherPostAgentSearchStateRequest],
      POST_AGENT_SEARCH_CITY_REQUEST: [watcherPostAgentSearchCityRequest],
      POST_AGENT_PROJECT_CREATE_REQUEST: [watcherPostAgentProjectCreateRequest],
      GET_AGENT_LEAD_LIST_REQUEST: [watcherGetAgentLeadListRequest],
      PUT_AGENT_LEAD_DETAIL_REQUEST: [watcherPutAgentLeadDetailRequest],
      GET_AGENT_COMMON_CITY_REQUEST: [watcherGetAgentCommonCityRequest],
      GET_AGENT_COMMON_STATE_REQUEST: [watcherGetAgentCommonStateRequest],
      PUT_AGENT_LISTING_DETAIL_IMAGE_REQUEST: [
        watcherPutAgentListingDetailImageRequest
      ],
      POST_AGENT_SUBSCRIPTION_PACKAGE_REQUEST: [
        watcherPostAgentSubscriptionPackageRequest
      ]
    }
  };
};
