/**
 * Copyright 2020 - present, Alpstein Technology Sdn Bhd.
 * All rights reserved.
 */
import _ from "lodash";
import React from "react";
import Popover from "../../components/ProjectDetailTab/LoanTab";
import Constants from "../constants";
const isProduction = process.env.NODE_ENV === "production";

const LEGAL_FIRST_LEVEL = 1;
const LEGAL_SECOND_LEVEL = 0.8;
const LEGAL_THIRD_LEVEL = 0.7;
const LEGAL_FOURTH_LEVEL = 0.6;
const LEGAL_FIFTH_LEVEL = 0.5;
const LEGAL_SIXTH_LEVEL = 0.4;

const STAMP_DUTY_FIRST_LEVEL = 1;
const STAMP_DUTY_SECOND_LEVEL = 2;
const STAMP_DUTY_THIRD_LEVEL = 3;
const STAMP_DUTY_FOURTH_LEVEL = 4;

const arrayToString = (obj, separator = ",") =>
  Object.keys(obj)
    .map(key => obj[key])
    .join(separator);

const trackPageView = url => {
  try {
    window.gtag("config", "UA-XXXXXXXX-X", {
      page_location: url
    });
  } catch (error) {
    // silences the error in dev mode
    // and/or if gtag fails to load
  }
};

const secToMin = seconds => {
  let secs = Math.round(seconds);

  if (secs < 0) throw new Error("Seconds must be positive");

  if (secs < 60) {
    if (secs < 10) return `0:0${secs}`;

    return `0:${secs}`;
  }

  let minuteDivisor = secs % (60 * 60);
  let minutes = Math.floor(minuteDivisor / 60);

  let secondDivisor = minuteDivisor % 60;
  let remSecs = Math.ceil(secondDivisor);

  if (remSecs < 10 && remSecs > 0) remSecs = `0${remSecs}`;
  if (remSecs === 0) remSecs = `${remSecs}0`;

  let time = {
    m: minutes,
    s: remSecs
  };

  return `${time.m}:${time.s}`;
};

const dateToYMD = date => {
  let d = date.getDate();
  let m = date.getMonth() + 1; //Month from 0 to 11
  let y = date.getFullYear();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
};

const formatToDatePicker = date => {
  let month = date.getMonth() + 1;

  let day = date.getDate();

  let year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const downPaymentCalculate = (propertyPrice, downPaymentRate) => {
  if (propertyPrice === 0 || downPaymentRate === 0) {
    return 0;
  }

  return _.ceil((propertyPrice * 100 * downPaymentRate) / 100 / 100, 2);
};

const downPaymentCalculateWithAmount = (propertyPrice, downPaymentAmount) => {
  if (propertyPrice === 0 || downPaymentAmount === 0) {
    return 0;
  }

  return _.ceil(propertyPrice - downPaymentAmount, 2);
};

const getLoanAmount = (propertyPrice, downPayment) => {
  if (propertyPrice === 0) {
    return 0;
  }

  if (downPayment === 0) {
    return propertyPrice;
  }
  return (propertyPrice * 100 - downPayment * 100) / 100;
};

const getMonthlyLoan = (loanAmount, interestRate, loanTerm) => {
  if (loanAmount === 0) {
    return "-";
  }
  if (interestRate === 0) {
    return loanAmount;
  }

  return _.ceil(
    (interestRate / 1200 +
      interestRate /
        1200 /
        ((1 + interestRate / 1200) ** (loanTerm * 12) - 1)) *
      loanAmount,
    2
  );
};

const recursiveFormula = (legalFee, propertyPrice, limit, percentage) => {
  let newLegalFee = legalFee;
};

const getLegalFee = propertyPrice => {
  if (propertyPrice <= 0) {
    return 0;
  }

  let newPropertyPrice = propertyPrice;
  let legalFee = 0;
  if (newPropertyPrice <= 500000) {
    legalFee = legalFee + _.ceil((propertyPrice * LEGAL_FIRST_LEVEL) / 100, 2);
    if (legalFee < 500) {
      return 500;
    }
    return legalFee;
  }

  if (newPropertyPrice > 500000) {
    legalFee = legalFee + _.ceil((500000 * LEGAL_FIRST_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 500000;
  }

  if (newPropertyPrice <= 500000) {
    legalFee =
      legalFee + _.ceil((newPropertyPrice * LEGAL_SECOND_LEVEL) / 100, 2);
    return legalFee;
  }

  if (newPropertyPrice > 500000) {
    legalFee = legalFee + _.ceil((500000 * LEGAL_SECOND_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 500000;
  }

  if (newPropertyPrice <= 2000000) {
    legalFee =
      legalFee + _.ceil((newPropertyPrice * LEGAL_THIRD_LEVEL) / 100, 2);
    return legalFee;
  }

  if (newPropertyPrice > 2000000) {
    legalFee = legalFee + _.ceil((2000000 * LEGAL_THIRD_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 2000000;
  }

  if (newPropertyPrice <= 2000000) {
    legalFee =
      legalFee + _.ceil((newPropertyPrice * LEGAL_FOURTH_LEVEL) / 100, 2);
    return legalFee;
  }

  if (newPropertyPrice > 2000000) {
    legalFee = legalFee + _.ceil((2000000 * LEGAL_FOURTH_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 2000000;
  }

  if (newPropertyPrice <= 2500000) {
    legalFee =
      legalFee + _.ceil((newPropertyPrice * LEGAL_FIFTH_LEVEL) / 100, 2);
    return legalFee;
  }

  if (newPropertyPrice > 2500000) {
    legalFee = legalFee + _.ceil((2500000 * LEGAL_FIFTH_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 2500000;
  }

  if (newPropertyPrice > 0) {
    legalFee =
      legalFee + _.ceil((newPropertyPrice * LEGAL_SIXTH_LEVEL) / 100, 2);
  }

  return legalFee;
};

const getStampDutyFee = propertyPrice => {
  if (propertyPrice <= 0) {
    return 0;
  }

  let newPropertyPrice = propertyPrice;
  let stampDutyFee = 0;
  if (newPropertyPrice <= 100000) {
    stampDutyFee =
      stampDutyFee + _.ceil((propertyPrice * STAMP_DUTY_FIRST_LEVEL) / 100, 2);

    return stampDutyFee;
  }

  if (newPropertyPrice > 100000) {
    stampDutyFee =
      stampDutyFee + _.ceil((100000 * STAMP_DUTY_FIRST_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 100000;
  }

  if (newPropertyPrice <= 400000) {
    stampDutyFee =
      stampDutyFee +
      _.ceil((newPropertyPrice * STAMP_DUTY_SECOND_LEVEL) / 100, 2);
    return stampDutyFee;
  }

  if (newPropertyPrice > 400000) {
    stampDutyFee =
      stampDutyFee + _.ceil((400000 * STAMP_DUTY_SECOND_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 400000;
  }

  if (newPropertyPrice <= 500000) {
    stampDutyFee =
      stampDutyFee +
      _.ceil((newPropertyPrice * STAMP_DUTY_THIRD_LEVEL) / 100, 2);
    return stampDutyFee;
  }

  if (newPropertyPrice > 500000) {
    stampDutyFee =
      stampDutyFee + _.ceil((500000 * STAMP_DUTY_THIRD_LEVEL) / 100, 2);
    newPropertyPrice = newPropertyPrice - 500000;
  }

  if (newPropertyPrice > 0) {
    stampDutyFee =
      stampDutyFee +
      _.ceil((newPropertyPrice * STAMP_DUTY_FOURTH_LEVEL) / 100, 2);
  }

  return stampDutyFee;
};

const legalFeeDesc = () => {
  return (
    <div>
      <p style={{ fontWeight: "600", fontSize: "1.2rem" }}>
        Legal Fee Calculator Description
      </p>
      <ul>
        <li>First RM500,000: 1%</li>
        <li>RM500,001 – RM1,000,000: 0.8%</li>
        <li>RM1,000,001 – RM3,000,000: 0.7%</li>
        <li>RM3,000,001 – RM5,000,000: 0.6%</li>
        <li>Above RM5,000,0001: 0.5% (negotiable)</li>
      </ul>
    </div>
  );
};

const StampDutyDesc = () => {
  return (
    <div>
      <p style={{ fontWeight: "600", fontSize: "1.2rem" }}>
        SPA Memorandum of Transfer (MoT) Stamp Duty
      </p>
      <ul>
        <li> First RM100,000: 1%</li>
        <li>RM100,001 – RM500,000: 2%</li>
        <li> RM500,001 – RM1,000,000: 3%</li>
        <li>Above RM1,000,001: 4%</li>
      </ul>
    </div>
  );
};

const openWhatsappLink = (number, text) => {
  const number1 = `60146160394`;
  const text1 = `Hello Leong,I am interest in you~~~~`;

  window.open(`https://wa.me/${number}/?text=${text}`, "_blank");
};

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d / 2 >= 849 ? 849 : d / 2 < 1 ? 1 : d / 2;
};

const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

const firebaseSignupErrorCode = errorCode => {
  switch (errorCode) {
    case "auth/email-change-needs-verification":
      return "Email needs verification";
      break;
    case "auth/email-already-in-use":
      return "Email already used";
      break;
    case "auth/user-token-expired":
      return "User token expired";
      break;
    case "auth/too-many-requests":
      return "Too many requests";
      break;
    case "auth/unverified-email":
      return "Email unverified";
      break;
    case "auth/popup-blocked":
      return "Popup blocked";
      break;
    case "auth/popup-closed-by-user":
      return "Popup closed by user";
      break;
    case "auth/rejected-credential":
      return "Providers rejected";
      break;
    case "auth/account-exists-with-different-credential":
      return "Account existed with other providers";
      break;
    case "auth/invalid-email":
      return "Email Invalid";
      break;
    case "auth/invalid-user-token":
      return "User token invalid";
      break;
    case "auth/credential-already-in-use":
      return "Provider already used";
      break;
    default:
      return "Unknown error !";
      break;
  }
};

const formatContactNumber = number => {
  if (number === null) {
    return;
  } else {
    return number.replace(/\d{4}$/, "****");
  }
};

const isShowUnitBed = (category, childCategory) => {
  if (_.isEmpty(category) || _.isEmpty(childCategory)) {
    return false;
  }

  if (
    _.isEqual(category, Constants.PROPERTY_TYPE_RESIDENTIAL) &&
    !_.isEqual(
      childCategory,
      Constants.PROPERTY_TYPE_RESIDENTIAL_RESIDENTIAL_LAND
    )
  ) {
    return true;
  }

  if (
    _.isEqual(category, Constants.PROPERTY_TYPE_COMMERCIAL) &&
    _.isEqual(childCategory, Constants.PROPERTY_TYPE_COMMERCIAL_HOTEL_RESORT)
  ) {
    return true;
  }

  return false;
};

const validEmail = email => {
  return email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi);
};

const extractVideoID = url => {
  // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*|(shorts\/)([^#\&\?]*).*/;
  var match = url.match(regExp);
  // if (match && match[7].length == 11) {
  //   return `https://www.youtube.com/embed/${match[7]}`;
  // } else {
  //   //alert("Could not extract video ID.");}
  //   return false;
  // }
  if (match) {
    if (match[7] && match[7].length == 11) {
      return `https://www.youtube.com/embed/${match[7]}`;
    } else if (match[9] && match[9].length == 11) {
      return `https://www.youtube.com/embed/${match[9]}`;
    }
  }

  return false;
};

const extractVideoIDOnly = url => {
  if (_.isEmpty(url)) {
    return false;
  }
  // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*|(shorts\/)([^#\&\?]*).*/;
  var match = url.match(regExp);
  // if (match && match[7].length == 11) {
  //   return `${match[7]}`;
  // } else {
  //   //alert("Could not extract video ID.");}
  //   return false;
  // }
  if (match) {
    if (match[7] && match[7].length == 11) {
      return match[7];
    } else if (match[9] && match[9].length == 11) {
      return match[9];
    }
  }

  return false;
};

const laberColor = () => {
  return [
    {
      title: "Residential",
      lightColor: "#FFD8D2",
      // color: "#D93F2A"
      color: "#D13823"
    },
    {
      title: "Industrial",
      lightColor: "#D5CAF3",
      // color: "#FFD700"
      color: "#787A11"
    },
    {
      title: "Commercial",
      lightColor: "#FFD8D2",
      color: "#3462F4"
    },
    {
      title: "Agricultural",
      lightColor: "#FFD8D2",
      color: "#ec8d02"
    },
    {
      title: "Others",
      lightColor: "#C8D4F4",
      color: "#2c2c2c"
    }
  ];
};

const getCityAndState = (dynamicURL) => {
  return _.chain(dynamicURL)
  // Initial breakdown of the string
  .thru(str => {
      const cleaned = str.replace(/^in-/, ''); // remove in- prefix
      const lastStateIndex = cleaned.lastIndexOf('-state-'); // find the last occurance of -state-
      
      if (lastStateIndex === -1) { // if no -state- found, return the whole string as city and empty string as state
      return {
          city: cleaned,
          state: ''
      };
      }
      
      return {
          city: cleaned.substring(0, lastStateIndex), // city is everything before the last -state-
          state: cleaned.substring(lastStateIndex + 7) // state is everything after the last -state-
      };
  })
  // Transform both city and state
  .thru(parts => ({
      city: _.chain(parts.city)
      .thru(city => city.replace(/-/g, ' ')) // replace all - with space
      .trim() // remove leading and trailing spaces
      .value(),
      state: _.chain(parts.state)
      .thru(state => state.replace(/-/g, ' ')) // replace all - with space
      .trim() // remove leading and trailing spaces
      .value()
  }))
  .value();
}

export default {
  isProduction,
  trackPageView,
  secToMin,
  arrayToString,
  dateToYMD,
  formatToDatePicker,
  downPaymentCalculate,
  getLoanAmount,
  getMonthlyLoan,
  getLegalFee,
  getStampDutyFee,
  legalFeeDesc,
  StampDutyDesc,
  openWhatsappLink,
  getDistanceFromLatLonInKm,
  firebaseSignupErrorCode,
  formatContactNumber,
  isShowUnitBed,
  validEmail,
  extractVideoID,
  extractVideoIDOnly,
  downPaymentCalculateWithAmount,
  laberColor,
  getCityAndState
};
